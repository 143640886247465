@charset "UTF-8";
.cart-progress > .breadcrumb {
  border-radius: 5px;
  display: inline-block;
  list-style: none;
  padding: 0.3em;
}
.cart-progress > .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "›";
  padding: 0.5em 0;
}
.cart-progress > .breadcrumb .breadcrumb-item {
  display: inline-block;
}
.cart-progress > .breadcrumb .breadcrumb-item .inner {
  padding: 0.3em 0.5em;
  border-radius: 5px;
}
.cart-progress > .breadcrumb .breadcrumb-item.active .step-name {
  color: #335EEA;
  font-weight: bold;
}
@media (max-width: 576px) {
  .cart-progress .breadcrumb {
    display: block;
    text-align: left;
  }
  .cart-progress .breadcrumb .breadcrumb-item {
    display: block;
    margin-bottom: 0.5em;
    padding: 0;
  }
  .cart-progress .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: "";
  }
}

/** RACES PAGE */
#race-fees {
  width: auto;
}

#race-fees .base,
#race-fees .adjustment {
  background: #EEE;
  padding: 0;
}

#race-fees .active {
  background: #D7FF91;
}

#race-fees .inactive {
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

#race-fees .base .price,
#race-fees .adjustment .amount {
  padding: 8px;
  display: block;
  background: #FFF;
  font-weight: bold;
}

#race-fees .base .price .currency {
  font-weight: normal;
}

#race-fees .base .type,
#race-fees .adjustment .dates {
  display: block;
  font-size: 9px;
  line-height: 12px;
  color: #999;
  padding: 2px 5px;
}

#race-fees .adjustment .dates span {
  display: block;
}

#discounts {
  background-color: #A1D3F8;
  padding: 4px;
  margin-bottom: 15px;
}

#discounts .title {
  font-weight: 300;
  color: #fff;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 8px;
  background: transparent;
}

#discounts form {
  overflow: visible;
  margin-bottom: 3px;
}

.entries-table tr td.competitor-name .last_name,
.entries-table tr td.team_name {
  text-transform: uppercase;
}
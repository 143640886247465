.cart-progress {
  > .breadcrumb {
    border-radius: 5px;
    display: inline-block;
    list-style: none;
    padding: 0.3em;

    .active {
    }


    .breadcrumb-item + .breadcrumb-item::before {
      content: "›";
      padding: 0.5em 0;
    }

    .breadcrumb-item {
      display: inline-block;

      .inner {
        padding: 0.3em 0.5em;
        border-radius: 5px;
      }

      &.active {
        .step-name {
          color: $primary;
          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .breadcrumb {
      display: block;
      text-align: left;

      .breadcrumb-item {
        display: block;
        margin-bottom: 0.5em;
        padding: 0;
      }

      .breadcrumb-item + .breadcrumb-item::before {
        content: "";
      }
    }
  }
}
